import { VIDEOS_URL, DASHBOARD_URL } from "../configs/config";
import authHeader from "./authHeader";
import axios from 'axios';
const USER_API_URL = VIDEOS_URL + 'user/';

const getCurrentUser = () => {
  return axios.get(USER_API_URL, { headers: authHeader() });
};

const getQRDetails = () => {
  return axios.get(DASHBOARD_URL + "qrcode/fetch/All", { headers: authHeader() });
};

const saveQRDetails = (data: any) => {
  return axios.post(DASHBOARD_URL + "qrcode/add", data, { headers: authHeader() });
};

const updateQRDetails = (data: any) => {
  return axios.post(DASHBOARD_URL + "qrcode/update", data, { headers: authHeader() });
};

const getCompanyUsers = (data: any) => {
  return axios.get(DASHBOARD_URL + `company/users`, { params: data, headers: authHeader() });
};

const getDepartmentsByCompanyId = (companyId: string) => {
  return axios.get(DASHBOARD_URL + `company/${companyId}/departments`, { headers: authHeader() });
};

const getPopularVideos = (page: number) => {
  return axios.get(DASHBOARD_URL + "video/fetch/popular?page=" + page, { headers: authHeader() });
};

const getActivatedQRCount = () => {
  return axios.get(DASHBOARD_URL + "qrcode/fetch/Count", { headers: authHeader() });
};

const getPremiumUsersCount = (data: any) => {
  return axios.post(DASHBOARD_URL + "user/fetch/premium/count", data, { headers: authHeader() });
};

const getViewsCount = (data: any) => {
  return axios.post(DASHBOARD_URL + "video/fetch/views/count", data, { headers: authHeader() });
};

const getAllUsers = () => {
  return axios.get(DASHBOARD_URL + "user/fetch/all", { headers: authHeader() });
};

const getAllCompanies = () => {
  return axios.get(DASHBOARD_URL + "company/fetch/all", { headers: authHeader() });
};

const searchCompanyName = (searchInput: any) => {
  return axios.get(DASHBOARD_URL + "company/search/"+ searchInput, { headers: authHeader() });
};

const sendNotification = (data: any) => {
  return axios.post(DASHBOARD_URL + "notification/send", data, { headers: authHeader() });
};

const saveNotificationTemplate = (data: any) => {
  return axios.post(DASHBOARD_URL + "notification/add/template", data, { headers: authHeader() });
};

const getAllTemplates = () => {
  return axios.get(DASHBOARD_URL + "notification/fetch/qrcode/templates", { headers: authHeader() });
};

const getNotificationTemplates = () => {
  return axios.get(DASHBOARD_URL + "notification/get/template", { headers: authHeader() });
}

const uploadKMLFile = (data: any) => {
  return axios.post(DASHBOARD_URL + "challenge/upload/kml", data, { headers: {...authHeader(), "Content-Type": "multipart/form-data", "ngrok-skip-browser-warning":"any"} });
};

const getAllChallenges = () => {
  return axios.get(DASHBOARD_URL + "challenge/fetch/all", { headers: authHeader() });
};

const getAllArchivedChallenges = () => {
  return axios.get(DASHBOARD_URL + "challenge/fetch/all/archive", { headers: authHeader() });
};

const createNewChallenge = (data: any) => {
  return axios.post(DASHBOARD_URL + "challenge/create", data, { headers: authHeader() });
};

const getArchivedChallengeData = (id: string) => {
  return axios.get(DASHBOARD_URL + `challenge/archive/continue/${id}`, { headers: authHeader() });
};

const createNewArchiveChallenge = (data: any) => {
  return axios.post(DASHBOARD_URL + "challenge/create/archive", data, { headers: authHeader() });
};

const updateArchiveChallenge = (archiveId: string, data: any) => {
  return axios.post(DASHBOARD_URL + `challenge/create/archive/${archiveId}`, data, { headers: authHeader() });
};

const getChallengeDetailsById = (data: any) => {
  return axios.get(DASHBOARD_URL + `challenge/fetch/challengedetailsbylang`, { params: data, headers: authHeader() });
};

const updateChallenge = (data: any) => {
  return axios.post(DASHBOARD_URL + "challenge/update", data, { headers: authHeader() });
};

const createNewUser = (data: any) => {
  return axios.post(DASHBOARD_URL + "user/create", data, { headers: authHeader() });
};

const updateUserData = (data: any) => {
  return axios.post(DASHBOARD_URL + "user/update", data, { headers: authHeader() });
};

const deleteUser = (userId: string) => {
  return axios.delete(DASHBOARD_URL + `user/${userId}`, { headers: authHeader() });
};

const getAllNicknames = () => {
  return axios.get(DASHBOARD_URL + 'user/nicknames', { headers: authHeader() });
};

const getAllRegularChallenges = () => {
  return axios.get(DASHBOARD_URL + 'challenge/fetch/regularchallenges', { headers: authHeader() });
};

const deleteNotificationTemplate = (templateId: string) => {
  return axios.delete(DASHBOARD_URL + `notification/delete/template/${templateId}`, { headers: authHeader() });
};

const unpublishChallenge = (challengeId: string) => {
  return axios.delete(DASHBOARD_URL + `challenge/unpublish/${challengeId}`, { headers: authHeader() });
}

const deleteQrCode = (qrCodeId: number, cascadeDelete: boolean) => {
  return axios.delete(DASHBOARD_URL + `qrcode/delete/${qrCodeId}/?cascadeDelete=${cascadeDelete}`, { headers: authHeader() });
}

const getMilestoneByChallengeId = (challengeId: string) => {
  return axios.get(DASHBOARD_URL + `challenge/fetch/milestones/${challengeId}`, { headers: authHeader() });
}

const getMapUrlByChallengeId = (challengeId: string) => {
  return axios.get(DASHBOARD_URL + `challenge/fetch/map/${challengeId}`, { headers: authHeader() });
}

const getCompanies = () => {
  return axios.get(DASHBOARD_URL + 'company/', { headers: authHeader() });
}

const getCompanyById = (companyId: string) => {
  return axios.get(DASHBOARD_URL + `company/${companyId}`, { headers: authHeader() });
}

const saveCompany = (data: any) => {
  return axios.put(DASHBOARD_URL + `company/${data.id}`, data, { headers: authHeader() });
}

const saveNewCompany = (data: any) => {
  return axios.post(DASHBOARD_URL + 'company/', data, { headers: authHeader() });
}

const saveDepartment = (companyId: string, data: any) => {
  return axios.post(DASHBOARD_URL + `company/${companyId}/departments/`, data, { headers: authHeader() });
}

const updateDepartment = (companyId: string, departmentId: string, data: any) => {
  return axios.put(DASHBOARD_URL + `company/${companyId}/departments/${departmentId}`, data, { headers: authHeader() });
}

const createCompany = (data: any) => {
  return axios.post(DASHBOARD_URL + 'company/create', data, { headers: authHeader() });
}

const deleteCompany = (companyId: string) => {
  return axios.delete(DASHBOARD_URL + `company/${companyId}`, { headers: authHeader() });
}

const deleteDepartment = (companyId: string, departmentId: string) => {
  return axios.delete(DASHBOARD_URL + `company/${companyId}/departments/${departmentId}`, { headers: authHeader() });
}

const updateCompanyLock = (companyId: string, data: any) => {
  return axios.put(DASHBOARD_URL + `company/${companyId}/lock`, data, { headers: authHeader() });
}

const userService = {
  getCurrentUser,
  getQRDetails,
  saveQRDetails,
  getPopularVideos,
  getActivatedQRCount,
  getPremiumUsersCount,
  getViewsCount,
  getAllUsers,
  getAllCompanies,
  searchCompanyName,
  updateQRDetails,
  getCompanyUsers,
  getDepartmentsByCompanyId,
  sendNotification,
  saveNotificationTemplate,
  getAllTemplates,
  uploadKMLFile,
  getAllChallenges,
  getAllArchivedChallenges,
  createNewChallenge,
  createNewArchiveChallenge,
  getArchivedChallengeData,
  updateArchiveChallenge,
  getChallengeDetailsById,
  updateChallenge,
  createNewUser,
  updateUserData,
  deleteUser,
  getAllNicknames,
  getAllRegularChallenges,
  getNotificationTemplates,
  deleteNotificationTemplate,
  unpublishChallenge,
  deleteQrCode,
  getMilestoneByChallengeId,
  getMapUrlByChallengeId,
  getCompanies,
  getCompanyById,
  saveCompany,
  saveDepartment,
  updateDepartment,
  createCompany,
  deleteCompany,
  deleteDepartment,
  updateCompanyLock,
  saveNewCompany
};

export default userService;