import { Col, Input, Popconfirm, Row, Button } from "antd";
import Title from "antd/lib/typography/Title";
import "./UserList.css";
import { ColumnsType } from "antd/lib/table";
import { UserTableColumns } from "../../models/componentTypes/types";
import TableComponent from "../../components/table/table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { getAllUsers } from "../../features/common/common";
import { deleteUser } from "../../features/QRCodes/QRCodes";
import { DeleteOutlined } from "@ant-design/icons";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Notification from "../../components/notification/notification";
import * as XLSX from "xlsx";

const Search = Input.Search;

interface ColumnFilterItem {
  text: string;
  value: string;
}

const UserList = () => {
  const { token } = useAppSelector((state) => state.message);
  const { usersList } = useAppSelector((state) => state.common);
  const [users, setUsers] = useState<UserTableColumns[]>([]);
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.common);
  const [deleteResponse, setDeleteResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns: ColumnsType<UserTableColumns> = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => (a.firstName > b.firstName ? 1 : -1),
      width: `10%`,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => (a.lastName > b.lastName ? 1 : -1),
      width: `10%`,
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      key: "nickname",
      sorter: (a, b) => (a.nickname > b.nickname ? 1 : -1),
      width: `10%`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      defaultSortOrder: "ascend",
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      width: `20%`,
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Access Level",
      dataIndex: "accessLevel",
      key: "accessLevel",
      filters: Array.from(new Set(usersList.map(user => user.accessLevel || "(Blank)")))
      .sort()
      .map(accessLevel => ({ text: accessLevel, value: accessLevel })) as ColumnFilterItem[],
    onFilter: (value, record) => (record.accessLevel || "(Blank)").includes(value as string),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => (a.company > b.company ? 1 : -1),
      filters: Array.from(new Set(usersList.map(user => user.company || "(Blank)")))
      .sort()
      .map(company => ({ text: company, value: company })) as ColumnFilterItem[],
    onFilter: (value, record) => (record.company || "(Blank)").includes(value as string),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      sorter: (a, b) => (a.department > b.department ? 1 : -1),
      filters: Array.from(new Set(usersList.map(user => user.department || "(Blank)")))
      .sort()
      .map(department => ({ text: department, value: department })) as ColumnFilterItem[],
    onFilter: (value, record) => (record.department || "(Blank)").includes(value as string),
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      sorter: (a, b) => {
        if (!a.lastLogin) return 1;
        if (!b.lastLogin) return -1;
        return new Date(a.lastLogin).getTime() - new Date(b.lastLogin).getTime();
      },
      render: (text, record) => record.lastLogin ? new Date(record.lastLogin).toLocaleString() : "N/A",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => handleDeleteUser(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <div className="delete-button">
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleDeleteUser = (id: string) => {
    //dispatch delete user and then get all users
    dispatch(deleteUser(id))
      .then(() => {
        dispatch(getAllUsers()).then(() => {
          setDeleteResponse({
            status: "SUCCESS",
            successMessage: "User deleted successfully.",
            errors: [],
          });
          setOpen(true);
        });
      })
      .catch((response) => {
        setDeleteResponse({
          status: "ERROR",
          successMessage: "",
          errors: response.errors,
        });
        setOpen(true);
      });
  };

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  const exportColumns = [
    { header: "Name", key: "name" },
    { header: "Nickname", key: "nickname" },
    { header: "Email", key: "email" },
    { header: "Company", key: "company" },
    { header: "Department", key: "department" },
    { header: "Membership", key: "accessLevel" },
    { header: "Last Login", key: "lastLogin" }
  ];
  
  const mapUsersForExport = (users) => {
    return users.map(user => {
      const mappedUser = {};
      exportColumns.forEach(column => {
        if (column.key === "name") {
          mappedUser[column.header] = `${user.firstName} ${user.lastName}`;
        } else if (column.key === "lastLogin") {
          mappedUser[column.header] = user.lastLogin ? new Date(user.lastLogin).toLocaleString() : "N/A";
        } else {
          mappedUser[column.header] = user[column.key];
        }
      });
      return mappedUser;
    });
  };

  const exportToExcel = () => {
    const mappedUsers = mapUsersForExport(users);
    const worksheet = XLSX.utils.json_to_sheet(mappedUsers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
  
    //Apply bold formatting to the header cells
    if (worksheet['!ref']) {
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[cellAddress]) continue;
        if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {};
        worksheet[cellAddress].s.font = { bold: true };
      }
    }
  
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    const fileName = `Users_${formattedDate}.xlsx`;
  
    XLSX.writeFile(workbook, fileName);
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [token]);

  useEffect(() => {
    const filterAndSortUsers = () => {
      const filteredUsers = usersList.filter((user) => {
        return (
          user.nickname.toLowerCase().includes(searchText.toLowerCase()) ||
          user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          user.email.toLowerCase().includes(searchText.toLowerCase()) ||
          user.userType.toLowerCase().includes(searchText.toLowerCase()) ||
          user.accessLevel.toLowerCase().includes(searchText.toLowerCase()) ||
          user.company.toLowerCase().includes(searchText.toLowerCase()) ||
          user.department.toLowerCase().includes(searchText.toLowerCase())
        );
      });
  
      
      if (searchText === "") {
        setUsers(usersList);
      } else {
        // Apply sorting logic by email
        filteredUsers.sort((a, b) => {
          const emailA = a.email.toLowerCase();
          const emailB = b.email.toLowerCase();
          if (emailA > emailB) return 1;
          if (emailA < emailB) return -1;
          return 0;
        });
        setUsers(filteredUsers);
      }
    };
  
    filterAndSortUsers();
  }, [usersList, searchText]);

  return (
    <>
      <div className="user-list-container">
        <Row style={{ justifyContent: `space-between` }}>
          <div>
            <Title level={2} style={{ fontWeight: `700` }}>
              Users
            </Title>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
            <Button type="primary" style={{ marginLeft: 8 }} onClick={exportToExcel}>Export Users to Excel</Button> {/* Add Button */}
          </div>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ textAlign: "right" }}
          >
            <TableComponent Data={users} columns={columns} className="" 
              onChange={(pagination, filters, sorter) => {
                let filteredUsers = [...users];

                if (filters.accessLevel) {
                  filteredUsers = filteredUsers.filter(user =>
                    filters.accessLevel.includes(user.accessLevel || "(Blank)")
                  );
                }

                if (filters.company) {
                  filteredUsers = filteredUsers.filter(user =>
                    filters.company.includes(user.company || "(Blank)")
                  );
                }

                if (filters.department) {
                  filteredUsers = filteredUsers.filter(user =>
                    filters.department.includes(user.department || "(Blank)")
                  );
                }

                // Apply sorting logic
                if (sorter && sorter.field) {
                  filteredUsers.sort((a, b) => {
                    const valueA = a[sorter.field];
                    const valueB = b[sorter.field];
                    if (valueA > valueB) return sorter.order === 'ascend' ? 1 : -1;
                    if (valueA < valueB) return sorter.order === 'ascend' ? -1 : 1;
                    return 0;
                  });
                }

                setUsers(filteredUsers);
              }}
            />
          </Col>
        </Row>
      </div>
      <Notification
        open={open}
        setOpen={setOpen}
        title={deleteResponse?.status === "SUCCESS" ? "Success" : "Error"}
        message={
          deleteResponse?.successMessage
            ? deleteResponse.successMessage
            : deleteResponse?.errors
            ? deleteResponse.errors[0]
            : "Something went wrong, Please try again."
        }
        severity={deleteResponse?.status === "SUCCESS" ? "success" : "error"}
      />
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default UserList;
