import { useEffect, useState, createContext, useContext, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import {
  getCompanyById,
  updateDepartment,
  saveDepartment,
  deleteDepartment,
} from "../../features/common/common";
import TableComponent from "../../components/table/table";
import { ColumnsType } from "antd/es/table";
import {
  Input,
  Form,
  Checkbox,
  Row,
  Typography,
  FormInstance,
  Table,
  Popconfirm,
  Col,
  Space,
  Modal,
  Button,
} from "antd";
import { useParams } from "react-router-dom";
import "./Departments.css";
import type { InputRef } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Notification from "../../components/notification/notification"; // Import Notification component
const { Title } = Typography;

interface DepartmentTableColumns {
  id: string;
  departmentName: string;
  numberOfUsers: number;
  hasQrCode: boolean;
  userAccessLevel: string;
}

interface EditableRowProps {
  index: number;
}

const EditableContext = createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<{
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DepartmentTableColumns;
  record: DepartmentTableColumns;
  handleSave: (record: DepartmentTableColumns) => void;
}> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [editable, editing, form, dataIndex, record]);

  useEffect(() => {
    if (editing && editable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing, editable]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const updatedRecord = { ...record, ...values };
      handleSave(updatedRecord);
      if (id) {
        await dispatch(
          updateDepartment({
            companyId: id,
            departmentId: updatedRecord.departmentId,
            data: {
              departmentName: updatedRecord.departmentName,
              departmentId: updatedRecord.departmentId,
            },
          })
        );
        dispatch(getCompanyById(id)); // Reload the list
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[{ required: true, message: `Please input ${title}!` }]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={() => setEditing(false)}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Departments = () => {
  const { id } = useParams<{ id: string }>();
  const { token } = useAppSelector((state) => state.message);
  const { isLoading, company } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    status: "",
    message: "",
  });
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (id) {
      dispatch(getCompanyById(id));
    }
  }, [id, token]);

  useEffect(() => {
    if (isModalVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalVisible]);

  const isEditing = (record: DepartmentTableColumns) =>
    record.id === editingKey;

  const edit = (
    record: Partial<DepartmentTableColumns> & { id: React.Key }
  ) => {
    setEditingKey(record.id as string);
  };

  const save = async (id: string) => {
    try {
      setEditingKey(null);
      // Placeholder for save logic
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredDepartments =
    company.departments?.filter((department: any) =>
      department.departmentName.toLowerCase().includes(searchText.toLowerCase())
    ) || [];

  const handleSave = (row: DepartmentTableColumns) => {
    const newData = [...company.departments];
    const index = newData.findIndex((item: any) => row.id === item.id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      dispatch(getCompanyById(id!));
      setNotification({
        open: true,
        status: "SUCCESS",
        message: "Department saved successfully.",
      });
      // Update the state with the new data
    } else {
      newData.push(row);
    }
  };

  const handleCreateNewDepartment = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewDepartmentName("");
  };

  const handleSaveNewDepartment = async () => {
    if (id && newDepartmentName) {
      await dispatch(
        saveDepartment({
          companyId: id,
          data: {
            departmentName: newDepartmentName,
            departmentId: uuidv4(),
          },
        })
      );
      dispatch(getCompanyById(id));
      setIsModalVisible(false);
      setNewDepartmentName("");
      setNotification({
        open: true,
        status: "SUCCESS",
        message: "New department created successfully.",
      });
    }
  };

  const handleDelete = async (departmentId: string) => {
    if (id) {
      try {
        await dispatch(deleteDepartment({ companyId: id, departmentId }));
        dispatch(getCompanyById(id)); // Reload the list
        setNotification({
          open: true,
          status: "SUCCESS",
          message: "Department deleted successfully.",
        });
      } catch (error) {
        setNotification({
          open: true,
          status: "ERROR",
          message: "Failed to delete department.",
        });
        console.error("Failed to delete department:", error);
      }
    }
  };

  const columns = [
    {
      title: "Department Name",
      dataIndex: "departmentName",
      key: "departmentName",
      editable: true,
      width: "20%",
    },
    {
      title: "Number of Users",
      dataIndex: "numberOfUsers",
      key: "numberOfUsers",
      width: "20%",
    },
    {
      title: "Has QR Code",
      dataIndex: "hasQrCode",
      key: "hasQrCode",
      width: "20%",
      render: (text: boolean, record: DepartmentTableColumns) => (
        <Checkbox checked={text} disabled={true} />
      ),
    },
    {
      title: "User Access Level",
      dataIndex: "userAccessLevel",
      key: "userAccessLevel",
      editable: true,
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: string, record: DepartmentTableColumns) => (
        <Row gutter={16}>
          {record.numberOfUsers === 0 && (
            <Col>
              <Popconfirm
                title="Are you sure to delete this department?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <div className="delete-button">
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DepartmentTableColumns) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <div className="department-list-container">
        <Row style={{ justifyContent: `space-between` }}>
          <div>
            <Title level={2} style={{ fontWeight: `700` }}>
              Departments
            </Title>
          </div>
          <div>
            <Space>
              <Link to="/companies">Go back to list</Link>

              <Input.Search
                placeholder="Search by name"
                onSearch={handleSearch}
                style={{ width: 200 }}
              />
              <Button type="primary" onClick={handleCreateNewDepartment}>
                Create New Department
              </Button>
            </Space>
          </div>
        </Row>

        <Table
          columns={mergedColumns}
          dataSource={filteredDepartments}
          className="department-table"
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
        />
      </div>
      {isLoading && <LoadingSpinner />}

      <Modal
        title="Create New Department"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveNewDepartment}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label="Department Name"
            required
            rules={[
              { required: true, message: "Please input department name!" },
            ]}
          >
            <Input
              ref={inputRef}
              value={newDepartmentName}
              onChange={(e) => setNewDepartmentName(e.target.value)}
              onPressEnter={handleSaveNewDepartment}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Notification
        open={notification.open}
        setOpen={(open) => setNotification({ ...notification, open })}
        title={notification.status === "SUCCESS" ? "Success" : "Error"}
        message={notification.message}
        severity={notification.status === "SUCCESS" ? "success" : "error"}
      />
    </>
  );
};

export default Departments;
