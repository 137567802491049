import { useEffect, useState, createContext, useContext, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import {
  getCompanies,
  createCompany,
  saveCompany,
  deleteCompany,
  updateCompanyLock,
  saveNewCompany,
} from "../../features/common/common";
import TableComponent from "../../components/table/table";
import { ColumnsType } from "antd/es/table"; // Import ColumnsType from antd
import {
  Input,
  Form,
  Checkbox,
  Row,
  Typography,
  FormInstance,
  Table,
  Popconfirm,
  Col,
  Button,
  Modal,
  Radio, // Import Radio from antd
} from "antd"; // Import Input, Form, Checkbox, Row, and Typography from antd
import "./Companies.css"; // Import the Companies.css file
import type { InputRef } from "antd";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  LockOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation and useNavigate
import { v4 as uuidv4 } from "uuid"; // Import uuid for generating empty GUID
import Notification from "../../components/notification/notification"; // Import Notification component

const { Title } = Typography;

// Define the CompanyTableColumns type
interface CompanyTableColumns {
  id: string;
  name: string;
  hasQrCode: boolean;
  userAccessLevel: string;
  numberOfUsers: number;
  isOpen: boolean;
  password?: string; // Add password field
}

interface EditableRowProps {
  index: number;
}

const EditableContext = createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<{
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof CompanyTableColumns;
  record: CompanyTableColumns;
  handleSave: (record: CompanyTableColumns) => void;
}> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
  }, [editable, editing, form, dataIndex, record]);

  useEffect(() => {
    if (editing && editable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing, editable]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        save();
      }
    };

    if (editing) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
      await dispatch(saveCompany({ id: record.id, name: values[dataIndex] }));
      dispatch(getCompanies());
      setEditing(false);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[{ required: true, message: `Please input ${title}!` }]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={() => setEditing(false)}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Companies = () => {
  const { token } = useAppSelector((state) => state.message);
  const { isLoading, companies } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    status: "",
    message: "",
  });
  const [companyType, setCompanyType] = useState("Open"); // State for company type
  const [password, setPassword] = useState(""); // State for password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [isLockModalVisible, setIsLockModalVisible] = useState(false); // State for lock modal visibility
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyTableColumns | null>(null); // State for selected company
  const [currentPassword, setCurrentPassword] = useState(""); // State for current password

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search") || "";
    setSearchText(search);
  }, [location.search]);

  useEffect(() => {
    if (!(companies && companies.length > 0)) {
      dispatch(getCompanies());
    }
  }, [token, companies]);

  const isEditing = (record: CompanyTableColumns) => record.id === editingKey;

  const edit = (record: Partial<CompanyTableColumns> & { id: React.Key }) => {
    setEditingKey(record.id as string);
  };

  const save = async (id: string) => {
    try {
      setEditingKey(null);
      // Placeholder for save logic
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("search", value);
    navigate({ search: queryParams.toString() });
  };

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSave = (row: CompanyTableColumns) => {
    const newData = [...companies];
    const index = newData.findIndex((item) => row.id === item.id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      dispatch(getCompanies());
      // Update the state with the new data
    } else {
      newData.push(row);
    }
  };

  const handleDeleteCompany = async (companyId: string) => {
    try {
      await dispatch(deleteCompany(companyId));
      setNotification({
        open: true,
        status: "SUCCESS",
        message: "Company deleted successfully.",
      });
      await dispatch(getCompanies());
    } catch (err) {
      setNotification({
        open: true,
        status: "ERROR",
        message: "Failed to delete company.",
      });
    }
  };

  const handleLockClick = (record: CompanyTableColumns) => {
    setSelectedCompany(record);
    setCompanyType(record.isOpen ? "Open" : "Password");
    setCurrentPassword(""); // Set current password
    setIsLockModalVisible(true);
  };

  const handleLockSave = async () => {
    if (selectedCompany) {
      try {
        await dispatch(
          updateCompanyLock({
            companyId: selectedCompany.id,
            data: {
              id: selectedCompany.id,
              name: selectedCompany.name,
              isOpen: companyType === "Open",
              password: companyType === "Password" ? password : "",
              currentPassword: currentPassword,
              confirmPassword: confirmPassword,
            },
          })
        );
        setIsLockModalVisible(false);
        setSelectedCompany(null);
        dispatch(getCompanies());
        setNotification({
          open: true,
          status: "SUCCESS",
          message: "Company updated successfully.",
        });
      } catch (err) {
        setNotification({
          open: true,
          status: "ERROR",
          message: "Failed to update company.",
        });
      }
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      width: "40%",
    },
    {
      title: "Has QR Code",
      dataIndex: "hasQrCode",
      key: "hasQrCode",
      render: (text: boolean, record: CompanyTableColumns) =>
        text ? (
          <span style={{ fontSize: `24px` }}>
            <CheckCircleOutlined />
          </span>
        ) : (
          <></>
        ),
    },
    {
      title: "User Access Level",
      dataIndex: "userAccessLevel",
      key: "userAccessLevel",
    },
    {
      title: "Number of Users",
      dataIndex: "numberOfUsers",
      key: "numberOfUsers",
    },
    {
      title: "Is Open",
      dataIndex: "isOpen",
      key: "isOpen",
      render: (text: boolean, record: CompanyTableColumns) =>
        text ? (
          <span style={{ fontSize: `24px` }}>
            <CheckCircleOutlined />
          </span>
        ) : (
          <></>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: string, record: CompanyTableColumns) => (
        <Row gutter={16}>
          <Col>
            <Link to={`/companies/${record.id}`}>
              <div className="info-button">
                <InfoCircleOutlined />
              </div>
            </Link>
          </Col>
          {record.numberOfUsers == 0 && (
            <Col>
              <Popconfirm
                title="Are you sure to delete this company?"
                onConfirm={() => handleDeleteCompany(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <div className="delete-button">
                  <DeleteOutlined />
                </div>
              </Popconfirm>
            </Col>
          )}
          <Col>
            <div
              className="lock-button"
              onClick={() => handleLockClick(record)}
            >
              <LockOutlined />
            </div>
          </Col>
        </Row>
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: CompanyTableColumns) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      if (companyType === "Password" && password !== confirmPassword) {
        setNotification({
          open: true,
          status: "ERROR",
          message: "Passwords do not match.",
        });
        return;
      }

      const response = await dispatch(
        saveNewCompany({
          name: newCompanyName,
          password: companyType === "Password" ? password : "",
          confirmPassword: companyType === "Password" ? confirmPassword : "",
          IsOpen: companyType === "Open",
        })
      );

      console.log(response);

      setIsModalVisible(false);
      setNewCompanyName("");
      setPassword("");
      setConfirmPassword("");
      dispatch(getCompanies());
      setNotification({
        open: true,
        status: "SUCCESS",
        message: "Company created successfully.",
      });
    } catch (err) {
      setNotification({
        open: true,
        status: "ERROR",
        message: "Failed to create company.",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewCompanyName("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <>
      <div className="company-list-container">
        <Row style={{ justifyContent: `space-between` }}>
          <div>
            <Title level={2} style={{ fontWeight: `700` }}>
              Companies
            </Title>
          </div>
          <div>
            <Input.Search
              placeholder="Search by name"
              onSearch={handleSearch}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 200 }}
            />
            <Button
              type="primary"
              onClick={showModal}
              style={{ marginLeft: 8 }}
            >
              Create New Company
            </Button>
          </div>
        </Row>

        <Table
          columns={mergedColumns}
          dataSource={filteredCompanies}
          className="company-table"
          components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }}
        />
      </div>
      {isLoading && <LoadingSpinner />}

      <Modal
        title="Create New Company"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Company Name" required>
            <Input
              value={newCompanyName}
              onChange={(e) => setNewCompanyName(e.target.value)}
            />
          </Form.Item>
          <Form.Item required>
            <Radio.Group
              onChange={(e) => setCompanyType(e.target.value)}
              value={companyType}
            >
              <Radio value="Open">Open</Radio>
              <Radio value="Password">Password</Radio>
            </Radio.Group>
          </Form.Item>
          {companyType === "Password" && (
            <>
              <Form.Item label="Password" required>
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Confirm Password" required>
                <Input.Password
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>

      <Modal
        title="Edit Company Lock Type"
        visible={isLockModalVisible}
        onOk={handleLockSave}
        onCancel={() => {
          setIsLockModalVisible(false);
          setSelectedCompany(null); // Set selectedCompany to null
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setIsLockModalVisible(false);
              setSelectedCompany(null); // Set selectedCompany to null
            }}
          >
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleLockSave}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item required>
            <Radio.Group
              onChange={(e) => setCompanyType(e.target.value)}
              value={companyType}
            >
              <Radio value="Open">Open</Radio>
              <Radio value="Password">Password</Radio>
            </Radio.Group>
          </Form.Item>
          {companyType === "Password" && (
            <>
              {selectedCompany && !selectedCompany.isOpen && (
                <Form.Item label="Current Password" required>
                  <Input.Password
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Form.Item>
              )}
              <Form.Item label="New Password" required>
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Confirm New Password" required>
                <Input.Password
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>

      <Notification
        open={notification.open}
        setOpen={(open) => setNotification({ ...notification, open })}
        title={notification.status === "SUCCESS" ? "Success" : "Error"}
        message={notification.message}
        severity={notification.status === "SUCCESS" ? "success" : "error"}
      />
    </>
  );
};

export default Companies;
